:root {
    --color-white: rgba(255, 255, 255, 0.59);
    --color-light: #f1f5f9;
    --color-black: #121212;
    --color-night: #001632;
    --color-red: #f44336;
    --color-blue: #1a73e8;
    --color-gray: #ffffff;
    --color-grayish: #ffffff;
    --theme-color: #ff3951;
    --shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    list-style: none;
    list-style-type: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

body {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: var(--color-black);
    background: rgba(241, 241, 241, 0.55);
}

a,
button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    text-decoration: none;
}

img {
    display: block;
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
}

.navbar {
    box-shadow: var(--shadow-normal);
    /*padding: 0.2em;*/
    padding-inline: 3em;
}

.App-logo {
    height: 10em;
    pointer-events: none;
    width: 10em;
    position: absolute;
    margin-top: -27em;
    margin-left: -8rem;
    z-index: -1;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 3s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.heading1 {
    /* position: absolute; */
    /* top: 5em; */
    padding-inline: 5em;
    margin-bottom: 40px;
    text-align: center;
    max-width: 300px;
    border-bottom: 2px solid rgba(149, 149, 149, 0.64);
}

.heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SelectionBox {
    background: var(--color-gray);
    padding: 2em 3em;
    border-radius: 5px;
    margin-block: 5em;
    box-shadow: var(--shadow-normal)
}

.form-Selector {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.subTitleShape {
    width: 22px;
    max-height: 2px;
    min-height: 2px;
    margin-right: 10px;
    border-radius: 1000px;
    background-color: var(--theme-color);
}

.subHeading {
    color: var(--theme-color);
    margin-block: 1.5em;
}

.label-style {
    margin: 0 10px;
    width: 13em;
}

.mt-10 {
    margin-top: 10px;
}

.mr-10 {
    margin-right: 20px;
}

.w-5 {
    width: 9em;
}

.w-25 {
    width: 25em;
}

.used-field-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.sp-between {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-field {
    border: none;
    background-color: var(--color-light);
    padding: 10px;
    width: 15em;
    box-shadow: var(--shadow-normal)
}

.text-field2{
    border: none;
    background-color: var(--color-white);
    padding: 10px;
    width: 95%;
    margin-bottom: 15px;
    box-shadow: var(--shadow-normal);
    border: 1px solid #e4e4e6;
}

.defaultValueTab { 
    border: none;
    background-color: var(--color-white);
    padding: 10px;
    margin-bottom: 15px;
    box-shadow: var(--shadow-normal);
    border: 1px solid #e4e4e6;
    width: 100%;
}

.add-field-btn {
    background-color: var(--theme-color);
    color: #ffffff;
    border-radius: 5px;
    width: 5em;
    margin-inline: 5px;

}

.add-field-btn-disabled {
    background-color: var(--theme-color);
    opacity: 50%;
    color: #ffffff;
    border-radius: 5px;
    width: 5em;
    margin-inline: 5px;
    cursor: not-allowed;

}

.CustomField1,
.CustomField {
    border: 1px dashed var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 5em;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.35s ease;
}

.CustomField:hover {
    background-color: rgba(255, 57, 81, 0.15);
    transition: all 0.5s ease;
}

.submit-btn {
    background-color: var(--theme-color);
    padding: 10px 2em;
    border-radius: 10em;
    font-size: 18px;
    color: white;
    margin-block: 2em;
    font-weight: 500;
}

.submit-btn:hover {
    background-color: #c12b3e;
    transform:scale(103%);
    transition: 0.2s;
}


.container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90.7vh;
    width: 100%;
}

.wrapper1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: var(--color-black);
    background: var(--color-white);
    box-shadow: var(--shadow-large);
}

.wrapper {
    width: 28rem;
    padding: 2rem 2.5rem;
    border: none;
    outline: none;
    border-radius: 0.5rem;
    color: var(--color-black);
    background: var(--color-white);
    box-shadow: 0 3px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.wrapper input{
    border-radius: 6px;
}

.main .wrapper .form {
    width: 100%;
    height: auto;
    margin-top: 2rem;
}

.main .wrapper .form .input-control {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.25rem;
}

.main .wrapper .form .input-field {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: inherit;
    width: 100%;
    height: auto;
    padding: 0.75rem 1.25rem;
    border: none;
    outline: none;
    border-radius: 2rem;
    color: var(--color-black);
    background: var(--color-light);
    text-transform: unset;
    text-rendering: optimizeLegibility;
}

.main .wrapper .form .input-submit {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: inherit;
    cursor: pointer;
    min-width: 40%;
    height: auto;
    padding: 0.65rem 1.25rem;
    border: none;
    outline: none;
    border-radius: 2rem;
    color: #fff;
    background: var(--color-blue);
    box-shadow: var(--shadow-medium);
    text-transform: capitalize;
    text-rendering: optimizeLegibility;
}

.main .wrapper .striped {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.main .wrapper .striped-line {
    flex: auto;
    flex-basis: auto;
    border: none;
    outline: none;
    height: 2px;
    background: var(--color-grayish);
}

.main .wrapper .striped-text {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: inherit;
    color: var(--color-black);
    margin: 0 1rem;
}

.main .wrapper .method-control {
    margin-bottom: 1rem;
}

.main .wrapper .method-action {
    font-family: inherit;
    font-size: 0.95rem;
    font-weight: 500;
    line-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0.35rem 1.25rem;
    outline: none;
    border: 2px solid var(--color-grayish);
    border-radius: 2rem;
    color: var(--color-black);
    background: var(--color-white);
    text-transform: capitalize;
    text-rendering: optimizeLegibility;
    transition: all 0.35s ease;
}

.main .wrapper .method-action:hover {
    background: var(--color-light);
}


.top-pane {
    background-color: hsl(0, 0%, 92%);
}

.pane {
    height: 50vh;
    display: flex;
    position: relative;
}

.expand-collapse-button {
    position: absolute;
    right: 20px;
    top: 20px;
    background: rgba(93, 93, 93, 0.78);
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.editor-container {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: hsl(0, 0%, 92%);
}

.editor-container.collapsed {
    flex-grow: 0;
}

.editor-container.collapsed .CodeMirror-scroll {
    position: absolute;
    overflow: hidden !important;
}

.expand-collapse-btn {
    margin-left: 0.5rem;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.editor-title {
    display: flex;
    justify-content: space-between;
    background-color: var(--color-white);
    color: #5d5d5d;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}

.CodeMirror {
    height: 100% !important;
}

.code-mirror-wrapper {
    flex-grow: 1;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    overflow: hidden;
}

.chooseBtn {
    box-shadow: -7px -7px 20px 0px #ffffff,
    -4px -4px 5px 0px #ffffff,
    7px 7px 20px 0px #0002,
    4px 4px 5px 0px #0001,
    inset 0px 0px 0px 0px #ffffff,
    inset 0px 0px 0px 0px #0001,
    inset 0px 0px 0px 0px #ffffff,
    inset 0px 0px 0px 0px #0001;
    padding: 1em;
    width: 20em;
    height: 20em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chooseBtn:active {
    box-shadow: 4px 4px 6px 0 rgb(255, 255, 255),
    -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgb(255, 255, 255),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
}


.creating{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    font-size: 32px;
    font-weight: 600;
}

.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #ff3951;
    width: 65px;
    height: 65px;
    margin-bottom: 24px;
    -webkit-animation: spin 1.6s linear infinite; /* Safari */
    animation: spin 1.6s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  /* .slds-spinner_container{position:absolute;top:0;right:0;bottom:0;left:0;z-index:9050;background-color:hsla(0,0%,100%,.75);visibility:visible;opacity:1;transition:opacity .2s ease,visibility 0;transition-delay:0s,.3s;display:block} */
/* .slds-spinner{position:absolute;top:50%;left:50%;z-index:9051;transform:translate(-50%,-50%) rotate(90deg)} */
.slds-spinner,.slds-spinner__dot-a,.slds-spinner__dot-b{transform-origin:50% 50%;will-change:transform}
.slds-spinner__dot-a,.slds-spinner__dot-b{position:absolute;top:0;left:0;width:100%}
.slds-spinner:after,.slds-spinner:before,.slds-spinner__dot-a:after,.slds-spinner__dot-a:before,.slds-spinner__dot-b:after,.slds-spinner__dot-b:before{content:"";position:absolute;background:#0692DA;border-radius:50%;animation-duration:1s;animation-iteration-count:infinite;transform:translateZ(0)}
.slds-spinner__dot-a{transform:rotate(60deg)}
.slds-spinner__dot-b{transform:rotate(120deg)}
.slds-spinner:before{animation-delay:-.083s}
.slds-spinner__dot-a:before{animation-delay:.083s}
.slds-spinner__dot-b:before{animation-delay:.25s}
.slds-spinner:after{animation-delay:.41666667s}
.slds-spinner__dot-a:after{animation-delay:.583s}
.slds-spinner__dot-b:after{animation-delay:.75s}
@keyframes dotsBounceBefore-medium {
0%{transform:translateZ(0)}
60%{transform:translateZ(0);animation-timing-function:cubic-bezier(.55,.085,.68,.53)}
80%{transform:translate3d(-.5rem,0,0);animation-timing-function:cubic-bezier(0,1.11,.7,1.43)}
to{transform:translateZ(0)}
}
@keyframes dotsBounceAfter-medium {
0%{transform:translateZ(0)}
60%{transform:translateZ(0);animation-timing-function:cubic-bezier(.55,.085,.68,.53)}
80%{transform:translate3d(.5rem,0,0);animation-timing-function:cubic-bezier(0,1.11,.7,1.43)}
to{transform:translateX(0)}
}
.slds-spinner–large,.slds-spinner_large{width:2.75rem}
.slds-spinner–large .slds-spinner__dot-a:after,.slds-spinner–large .slds-spinner__dot-a:before,.slds-spinner–large .slds-spinner__dot-b:after,.slds-spinner–large .slds-spinner__dot-b:before,.slds-spinner–large.slds-spinner:after,.slds-spinner–large.slds-spinner:before,.slds-spinner_large .slds-spinner__dot-a:after,.slds-spinner_large .slds-spinner__dot-a:before,.slds-spinner_large .slds-spinner__dot-b:after,.slds-spinner_large .slds-spinner__dot-b:before,.slds-spinner_large.slds-spinner:after,.slds-spinner_large.slds-spinner:before{width:.625rem;height:.625rem}
.slds-spinner–large .slds-spinner__dot-a:before,.slds-spinner–large .slds-spinner__dot-b:before,.slds-spinner–large.slds-spinner:before,.slds-spinner_large .slds-spinner__dot-a:before,.slds-spinner_large .slds-spinner__dot-b:before,.slds-spinner_large.slds-spinner:before{animation-name:dotsBounceBefore-medium;top:-.3125rem;left:-.3125rem}
.slds-spinner–large .slds-spinner__dot-a:after,.slds-spinner–large .slds-spinner__dot-b:after,.slds-spinner–large.slds-spinner:after,.slds-spinner_large .slds-spinner__dot-a:after,.slds-spinner_large .slds-spinner__dot-b:after,.slds-spinner_large.slds-spinner:after{animation-name:dotsBounceAfter-medium;top:-.3125rem;right:-.3125rem}
@keyframes dotsBounceBefore-large {
0%{transform:translateZ(0)}
60%{transform:translateZ(0);animation-timing-function:cubic-bezier(.55,.085,.68,.53)}
80%{transform:translate3d(-.75rem,0,0);animation-timing-function:cubic-bezier(0,1.11,.7,1.43)}
to{transform:translateX(0)}
}
@keyframes dotsBounceAfter-large {
0%{transform:translateZ(0)}
60%{transform:translateZ(0);animation-timing-function:cubic-bezier(.55,.085,.68,.53)}
80%{transform:translate3d(.75rem,0,0);animation-timing-function:cubic-bezier(0,1.11,.7,1.43)}
to{transform:translateX(0)}
}

.spinner{
    width: 150px;
    height: 150px;
    position: relative;
    padding-bottom: 30px;
}

.slds-spinner_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.positioning{
    text-align: center;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
}

.dropdown{
    width: 218px;
    height: 20px;
    border: 1px solid black;
}

.close-icon{
    position: absolute;
    top: -8px;
    right: -14px;
    border-radius: 50%;
    cursor: pointer;
}

.close-icon svg{
    width: 20px;
    height: 20px;
}

.cursor-pointer{
    cursor: pointer;
}