@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700,800');

.layout{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 18px;
    position: relative;
}

.layout-bg{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 20px;
    font-size: 18px;
    position: relative;
    font-family: 'Lato',sans-serif !important;
}

.coding-block{
    padding: 20px;
    padding-right: 40px;
    background-color: #f2f2f7;
    border-radius: 20px;
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    position: relative;
    font-size: 14px;
}

.coding-block button{
    background-color: #f44336;
    border-radius: 10px;
    padding: 10px;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.coding-block code{
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
}

.red{
    color: #FF3851;
    background-color: #FF385117;
    padding: 10px 16px 10px 16px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    /* width: 300px; */
}

.red:hover{
    color: #ffffff;
    background-color: #FF3851;
    transition: 0.2s;
    transform: scale(103%);
}

.create-another{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bg-image{
    /* position: fixed; */
    z-index: 0;
}

.float-container{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.heading-1{
    font-size: 38px;
    /* background: linear-gradient(91.83deg, #FF5263 0.93%, #E7273A 111.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; */
}

.animate{
    width: 100px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
}